export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat/chat/index'),
    meta: {
      pageTitle: 'My Chats',
      breadcrumb: [{ text: 'Chat', active: true }],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/my-chat',
    name: 'my_chats',
    //component: () => import('@/views/chat/chat/index'),
    meta: {
      pageTitle: 'My Chats',
      breadcrumb: [{ text: 'Chat', active: true }],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/chats/show',
    name: 'global-chat',
    component: () => import('@/views/chat/chat/show'),
    meta: {
      pageTitle: 'Chat',
      breadcrumb: [
        { to: { name: 'chat' }, text: 'Chats' },
        { text: 'Chat Box', active: true },
      ],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/tickets/chats/:chat_id',
    name: 'ticket_chat',
    component: () => import('@/views/chat/chat/show'),
    meta: {
      pageTitle: 'Chat',
      breadcrumb: [
        { to: { name: 'chat' }, text: 'Chats' },
        { text: 'Chat Box', active: true },
      ],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  {
    path: '/broadcast/show',
    name: 'global-broadcast',
    component: () => import('@/views/broadcast/broadcast/show_broadcast'),
    meta: {
      pageTitle: 'Broadcast',
      breadcrumb: [
        { to: { name: 'broadcasts' }, text: 'Broadcast' },
        { text: 'Chat Box', active: true },
      ],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/broadcasts',
    name: 'broadcasts',
    component: () => import('@/views/broadcast/broadcast/index'),
    meta: {pageTitle: 'Broadcasts', breadcrumb: [{text: 'Communication'},{text: 'broadcasts'}]},
},
];
