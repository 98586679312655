import Resource from '../../resource'
import request from '../../../utils/request'

class MarkCategories extends Resource {
    constructor() {
        super('marks-categories')
    }


}

export { MarkCategories as default }