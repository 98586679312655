import Resource from '../../../api/resource';
import request from "../../../utils/request";

class Logs extends Resource {
    constructor() {
        super('logs');
    }


}

export {Logs as default};
