import TransferOut from '../../../../api/study/transfer/transferOut'

const transferOut = new TransferOut()

const transferModule = {
  namespaced: true,
  state: {
    load: false,
    total: null,
    items: [],
    lookups:[]
  },
  getters: {
    load(state) {
      return state.load
    },
    total(state) {
      return state.total
    },
    items(state) {
      return state.items
    },
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load
    },
    SET_ITEMS: (state, users) => {
      state.items = users
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    list({ commit }, query) {
      return new Promise((resolve, reject) => {
        transferOut.list(query)
          .then(response => {
            commit('SET_ITEMS', response.data)
            commit('SET_TOTAL_ITEMS', response.meta.count)
            commit('SET_LOOKUPS', response.meta.lookup);
            commit('SET_ITEMS_LOAD', false)
            resolve(response)
          })
          .catch(error => {
            reject(error.response.data.errors[0])
          })
      })
    },

    request({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transferOut.request(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response.data.errors[0])
          })
      })
    },

    delete({ commit }, transfer_id) {
      return new Promise((resolve, reject) => {
        transferOut.delete(transfer_id)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response.data.errors[0])
          })
      })
    },

    approve({ commit }, transfer_id) {
      return new Promise((resolve, reject) => {
        transferOut.approve(transfer_id)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response.data.errors[0])
          })
      })
    },
  },
}

export default transferModule
