import Resource from '../../resource';
import request from '../../../utils/request';

class Term extends Resource {
    constructor() {
        super('terms');
    }

}

export {Term as default};
