import Resource from '../../resource';
import request from '../../../utils/request';

class Slot extends Resource {
    constructor() {
        super('exam-slots');
    }
}

export {Slot as default};
