import resource from "@/api/resource";

class TermStage extends resource {
    constructor() {
        super('term-stages');
    }


}

export {TermStage as default};