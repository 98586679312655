import Resource from '../../../api/resource';
import request from '../../../utils/request';

class Specialization extends Resource {
    constructor() {
        super('students_ems');
    }

    list(payload) {
        return request({
            url: `/specialization/list`,
            method: 'get',
            params: payload,
        })
    }

    specializations(id) {
        return request({
            url: `/specialization/student-specializations/${id}`,
            method: 'get',
        })
    }

    deleteSpecialization(id) {
        return request({
            url: `/specialization/student-specialization/${id}`,
            method: 'delete',
        })
    }

    bulkSpecialize(payload) {
        return request({
            url: `/specialization/bulk-specialize/${payload.specialization_program}`,
            method: 'post',
            data: payload.query,
        })
    }
}

export {Specialization as default};
